<template>
  <v-card>
    <v-card-title>Добавить участника в проект</v-card-title>
    <v-card-text>
      <v-autocomplete
          :items="users"
          item-text="name"
          item-value="id"
          label="Пользователь"
          autocomplete="off"
          outlined
          no-data-text="Не найдено"
          v-model="data.user_id"
          hint="Вы можете выбрать только участника без проекта в рамках пула. "
      ></v-autocomplete>
      <v-autocomplete
                      :items="$store.state.projects.currentProject.roles" auto-select-first
          v-model="data.project_role_id"
                      autocomplete="off" outlined item-text="name" item-value="id"
                      label="Выберите вакансию"/>
      <v-btn :loading="loading" @click="save" :disabled="!data.project_role_id" rounded outlined color="primary">Добавить</v-btn>
    </v-card-text>
  </v-card>
</template>

<script>
import project_pools from "@/api/project_pools";

export default {
  name: "AddParticipantDialog",
  data(){
    return {
      users: [],
      loading: false,
      data: {
        user_id: null,
        project_role_id: null,
      }
    }
  },
  methods: {
    save(){
      this.loading = true;
      this.$store.dispatch('includeToProject', {...this.data, id: this.$store.state.projects.currentProject.id}).then(() => {
        this.loading = false;
        this.$emit('close');
      })
    }
  },
  mounted() {
    project_pools.getFreeUsers({id: this.$store.state.projects.currentProject.project_pools_id}).then((r) => {
        this.users = r.data;
    })
  }
}
</script>

<style scoped>

</style>
