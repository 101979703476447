<template>
  <div v-if="project.permissions.can_see_users">
    <!--    <v-sheet class="pa-4 mb-4">-->
    <!--      <h4 class="title">Роли проекта</h4>-->
    <!--      <v-list class="mb-4">-->
    <!--        <v-list-item v-for="role in project.roles" :key="role.id">-->
    <!--          <v-list-item-title>-->
    <!--            {{ role.name }}-->
    <!--          </v-list-item-title>-->

    <!--          <v-list-item-icon>-->
    <!--            <v-icon></v-icon>-->
    <!--          </v-list-item-icon>-->

    <!--          <v-list-item-subtitle class="text-right">-->
    <!--            {{ $store.getters.getParticipantsByProjectRole(role.id).length }} /-->
    <!--            {{ role.quota }} чел-->
    <!--          </v-list-item-subtitle>-->
    <!--          <v-list-item-action>-->
    <!--            <v-progress-linear-->
    <!--                :value="$store.getters.getParticipantsByProjectRole(role.id).length/role.quota*100"></v-progress-linear>-->
    <!--          </v-list-item-action>-->
    <!--        </v-list-item>-->
    <!--      </v-list>-->

    <!--      <v-btn v-if="project.permissions.can_edit_roles"-->
    <!--             :to="{name: 'project.roles'}" outlined rounded small color="primary">Редактировать роли</v-btn>-->
    <!--    </v-sheet>-->

    <v-sheet style="border-radius: 20px;" >
      <div class="pa-4 mb-4">
        <h3 class="title">Участники проекта</h3>
        <v-btn  @click="showAddParticipantDialog = true" dark outlined rounded
               small v-if="project.project_pools_id && $store.getters.checkPermission('can_manage_pools')" color="green">Добавить участника
        </v-btn>
      </div>

    </v-sheet>
    <v-dialog max-width="500" v-model="showAddParticipantDialog">
      <AddParticipantDialog
          @close="() => {this.showAddParticipantDialog = false;}"/>
    </v-dialog>


    <v-row style="align-items: stretch">
      <v-col v-for="participant in participants" :key="participant.id" lg="6" sm="6" cols="12">
        <div class="participant">
          <span class="mb-2 d-block" style="font-size: 15px;">
               <v-chip small color="primary">{{ participant.project_role.name }}</v-chip>
            </span>
          <v-avatar class="mx-auto d-block" size="140">
            <v-img :lazy-src="participant.avatar_16" :src="participant.avatar"/>
          </v-avatar>

          <h3 style="font-weight: 400; font-size: 20px;" class="mt-5">
            {{ participant.first_name }} {{ participant.last_name }}
          </h3>
          <p class="mt-2">
            <span v-if="participant.student_group" style="font-size: 15px; font-weight: 200">
              гр. {{ participant.student_group.name }}, {{ participant.student_group.department.name }}, {{ participant.student_group.department.institute.name }}
            </span>
          </p>
          <div>
            <div
                v-if="participant.pivot.approved && project.permissions.can_manage_users">
              <v-switch :disabled="participant.id === project.responsible_user_id" @change="changePermission(participant, $event)" color="primary"
                        v-model="participant.pivot.admin" label="Права администратора"/>
            </div>
            <v-btn x-small
                   class="ma-2"
                   color="primary" outlined small rounded @click="$router.push({path: $vuetify.breakpoint.smAndDown ? '/messages' : '#', query: {chat_user_id: participant.id}})">
              <v-icon left x-small>mdi-chat</v-icon>
              Написать
            </v-btn>
            <v-btn x-small  v-if="participant.pivot.approved && $store.getters.checkPermission('can_manage_projects')"
                   class="ma-2"
                   color="error" outlined small rounded @click="decline(participant)">
              <v-icon left x-small>mdi-account-minus</v-icon>
              Выгнать
            </v-btn>

          </div>
          <div class="w-100" v-if="!participant.pivot.approved && project.permissions.can_manage_users">
            <v-btn :loading="loading && participant.loading === 'approve'" outlined  dark
                   class="ma-2"
                   small
                   color="success" rounded @click="approve(participant)">
              <v-icon left>mdi-account-plus</v-icon>
              Одобрить
            </v-btn>
            <v-btn :loading="loading && participant.loading === 'decline'" outlined
                   class="ma-2"
                   color="red" dark small rounded @click="decline(participant)">
              <v-icon left x-small>mdi-cancel</v-icon>
              Отклонить
            </v-btn>
          </div>
        </div>
      </v-col>
    </v-row>

  </div>
</template>

<script>
import AddParticipantDialog from "@/components/Projects/ProjectsItem/AddParticipantDialog";

export default {
  name: "ProjectsItemParticipantsComponent",
  components: {AddParticipantDialog},
  computed: {
    project() {
      return this.$store.state.projects.currentProject;
    },
    participants() {
      return [...this.$store.state.projects.currentProjectParticipants].sort((x, y) => x.pivot.approved - y.pivot.approved);
    },

    readOnly() {
      return true
    }
  },
  methods: {
    approve(user) {
      if (confirm('Вы уверены, что хотите одобрить участие в проекте?')) {
        user.loading = 'approve'
        this.loading = true
        this.$store.dispatch('approveUserOnProject', {id: this.project.id, user_id: user.id}).finally(() => {
          user.loading = false
          this.loading = false
          window.jsConfetti.addConfetti({
            confettiRadius: 6,
            confettiNumber: 10, emojis: ['👍']
          });
        })
      }
    },
    decline(user) {
      if (confirm(user.pivot.approved ? 'Вы уверены, что хотите выгнать участника?' : 'Вы уверены, что хотите отказать?')) {
        user.loading = 'decline';
        this.loading = true;
        this.$store.dispatch('declineUserOnProject', {id: this.project.id, user_id: user.id}).finally(() => {
          user.loading = false
          this.loading = false
          window.jsConfetti.addConfetti({
            confettiRadius: 6,
            confettiNumber: 50, emojis: ['😭', '😥']
          });
        })
      }
    },
    changePermission(user, admin_mode) {
      this.$store.dispatch('changeParticipantPermission', {
        id: this.project.id,
        user_id: user.id,
        admin: admin_mode
      }).then(() => {
        this.$store.dispatch('getCurrentProject', {id: this.$route.params.project_id});
      })

    }
  },
  data() {
    return {
      loading: false,
      showAddParticipantDialog: false
    }
  }
}
</script>

<style scoped>
.participant {
  padding: 10px;
  min-height: 370px;
  border-radius: 20px;
  background: white;
  position: relative;
  box-shadow: 1px 1px 8px 0px rgba(34, 60, 80, 0.2);
}
</style>
